import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    // const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    // const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.Message;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              <form
                action=""
                method="post"
                id="contactForm"
                name="contactForm"
                data-netlify="true"
              >
                <fieldset>
                  <div>
                    <label htmlFor="Name">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="Name"
                      name="Name"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="Email">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      defaultValue=""
                      size="35"
                      id="Email"
                      name="Email"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="Subject">Subject</label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="Subject"
                      name="Subject"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="Message">
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      cols="40"
                      rows="15"
                      id="Message"
                      name="Message"
                    ></textarea>
                  </div>

                  <div>
                    <button className="submit" type="submit">
                      Submit
                    </button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Error</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Address and Phone</h4>
                <p className="address">
                  {name}
                  <br />
                  {city}, {state}
                  <br />
                  <span>{phone}</span>
                </p>
              </div>

              <div className="widget widget_tweets">
                <h4 className="widget-title">Other contact methods</h4>
                <ul id="twitter">
                  <li>
                    <span>
                      Feel free to reach me on LinkedIn, email, or phone.
                      {/* <li>
                        <a href="./" className="fa fa linkedin">
                          Linkedin
                        </a>{" "}
                        |
                      </li> */}
                    </span>
                  </li>
                </ul>
              </div>
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
